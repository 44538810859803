<template>
  <div>
    <div class="pa-6">
      <p class="font-weight-medium">
        {{ $t("multi_url.description") }}
      </p>

      <div class="mt-6 div-documents" v-if="documentList && documentList.length">
        <p>
          <span class="font-weight-bold">{{ $t("multi_url.documents") }}</span>
        </p>

        <v-list class="document-item bullet-list" dense flat light>
          <v-list-item v-for="(document, index) in documentList" :key="index">
            <v-list-item-content dense>
              <v-list-item-title class="text--secondary">
                {{ document.signatureTransaction.description }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>


    <div class="footer-message">
      <v-container class="px-2 fill-height">
        <v-row>
          <v-col cols="12" class="d-flex align-start">
            <v-checkbox dark v-model="termsAccepted" color="#F36E21" :disabled="!documentList.length"></v-checkbox>
            <p class="ml-2">
              {{ $t("multi_url.agreement") }}
              <a href="https://www.portasigma.com/politica-privacidad/" target="_blank" class="white--text"
                rel="noopener">{{ $t("multi_url.privacy_policy") }}</a>
            </p>
          </v-col>
          <v-col cols="12">
            <v-btn v-if="authCitizenId" x-large block color="#F36E21" dark elevation="1" :disabled="!termsAccepted"
              :loading="loading" @click="signDocument()">
              {{ $t("multi_url.enter_to_sign") }}
            </v-btn>
            <v-btn v-else x-large block color="#F36E21" dark elevation="1" :disabled="!termsAccepted" :loading="loading"
              @click="signOneTimeDocument()">
              {{ $t("multi_url.enter_to_sign") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <access-modal :dialog="visibleAccessDialog" :documentId="documentId" :documentToken="documentToken"
      :documentName="documentName" @closeModal="closeAccessModal()" />

    <default-loading v-if="loading" :loading="loading" />
  </div>
</template>

<script>
import AccessModal from "./shared/AccessModal.component";
import DefaultLoading from "../shared/DefaultLoading.component";

import { SignaturesService } from '../../services/multiple/signatures-service';
import UploadService from '../../services/multiple/upload-service.js'

export default {
  components: {
    DefaultLoading,
    AccessModal,
  },
  data() {
    return {
      termsAccepted: false,
      loading: false,
      documentList: [],
      token: null,
      authId: null,
      visibleAccessDialog: false,
      documentId: null,
      documentToken: null,
      documentName: null,
    }
  },
  computed: {
    hasAssociation() {
      return this.$store.getters['common/hasAssociation']
    }
  },
  methods: {
    async loadDocumentList() {
      this.loading = true;
      SignaturesService.getBatchCertificateSignatureRequests(this.token, this.authId)
        .then(async (response) => {
          this.documentList = response.data.signatureRequestsList || [];
          if (!localStorage.getItem('documentCheckList')) {
            await this.createDocumentCheckList();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    //Get Uploaded Attachment Count
    async getUploadedDocCount($request_id, $request_token, $request_auth_id = null) {
      const attachments = await UploadService.getAttachments($request_id, $request_token, $request_auth_id);
      return attachments.data.length;
    },
    // Create Document Check List
    async createDocumentCheckList() {
      let documentCheckList = [];
      for (const document of this.documentList) {
    
        let docCount = 0;
        // Using for...of to properly handle async/await
        if (document.signerId == null) {
          docCount = await this.getUploadedDocCount(document.id, document.apiToken, null);
        } else {
          docCount = await this.getUploadedDocCount(document.id, document.apiToken, document.signerId.id);
        }

        docCount = Number(docCount);


        if (isNaN(docCount)) {
          console.error('docCount is not a number:', docCount);
          docCount = 0;  // Set default to 0 if NaN
        }

        let stepCheckId = this.authCitizenId === 'citizenid';
        let stepCheckAttachments = document.hasAttachments;
        let stepCheckSignerForm = document.hasSignerForm;

        documentCheckList.push({
          documentId: document.id,
          documentStatus: document.status,
          documentToken: document.apiToken,
          documentName: document.signatureTransaction.description,
          checkId: stepCheckId,
          idChecked: false,
          checkAttachments: stepCheckAttachments,
          attachmentsChecked: false,
          checkSignerForm: stepCheckSignerForm,
          signerFormChecked: false,
          uploadedDocCount: docCount
        });
      }
      // Ensure documentCheckList is not empty before storing
      if (documentCheckList.length > 0) {
        localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));
        localStorage.setItem("type", this.documentList[0].type ? this.documentList[0].type : "");  // Storing additional data if available
      } else {
        console.log('documentCheckList is empty, nothing to store.');
      }
    },
    signDocument() {
      let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
      let firstDoc = documentCheckList && documentCheckList.length ? documentCheckList[0] : null;
      if (this.authCitizenId) {
        if (firstDoc.checkId && !firstDoc.idChecked) {
          this.documentId = firstDoc.documentId;
          this.documentToken = firstDoc.documentToken;
          this.documentName = firstDoc.documentName;
          this.visibleAccessDialog = true;
        }
      }

    },
    signOneTimeDocument() {
      let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
      let firstDoc = documentCheckList && documentCheckList.length ? documentCheckList[0] : null;
      this.confirmOneTimeRequest(firstDoc.documentId, firstDoc.documentToken);
    },
    closeAccessModal() {
      this.visibleAccessDialog = false;
    },
    viewDocument(document) {
      this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}show-document`, params: { id: document.id }, query: { token: document.apiToken, authId: this.authId } });
    },
    confirmOneTimeRequest(requestID, requestToken) {
      this.error = false;
      this.errorMsg = "";
      SignaturesService.getSignatureRequestDataAuth(
        requestID,
        this.citizenId,
        false,
        requestToken
      )
        .then(() => {
          this.error = false;
          this.loading = false;
          this.checkForNextStep();

        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status == 403) {
            this.error = true;
            this.errorMsg = error.response.data.description;
          }
        });

    },
    async checkForNextStep() {
      let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];

      // Update the document checklist after all status updates are completed
      documentCheckList.forEach((document) => {
        document.checkId = false;
        document.idChecked = true;
        document.isMultiple = true;
      });

      localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));

      // Using a for...of loop to handle async/await
      for (const [index, document] of documentCheckList.entries()) {
        console.log(index);
        await this.updateDocumentStatus(document, this.citizenId); // Await ensures each update completes before continuing
      }



      // Check for next steps after updating statuses
      for (const document of documentCheckList) {

        if (document.uploadedDocCount > 0) {
          this.$router.push({
            name: `${this.hasAssociation ? 'custom-' : ''}multiple-forms`,
            params: { id: document.documentId },
            query: {
              ...this.$route.query,
              token: document.documentToken,
              auth_id: this.citizenId,
              document_name: document.documentName
            }
          });
          break;
        } else if (document.checkAttachments && !document.attachmentsChecked) {
          this.$router.push({
            name: `${this.hasAssociation ? 'custom-' : ''}multiple-attachments`,
            params: { id: document.documentId },
            query: {
              ...this.$route.query,
              token: document.documentToken,
              auth_id: this.citizenId,
              document_name: document.documentName
            }
          });

          break;
        } else if (document.checkSignerForm && !document.signerFormChecked && document.checkAttachments && (document.uploadedDocCount > 0)) {

          this.$router.push({
            name: `${this.hasAssociation ? 'custom-' : ''}multiple-forms`,
            params: { id: document.documentId },
            query: {
              ...this.$route.query,
              token: document.documentToken,
              auth_id: this.citizenId,
              document_name: document.documentName
            }
          });
        }
      }
    },

    //update document attachments and form status
    async updateDocumentStatus(document) {
      let documentRequestID = document.documentId;
      let currentDocumentToken = document.documentToken;

      try {
        const response = await SignaturesService.getSignatureRequestDataAuth(
          documentRequestID,
          this.citizenId,
          this.isAuthCitizenId,
          currentDocumentToken
        );
        this.error = false;
        this.loading = false;
        let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
        documentCheckList.forEach((document) => {
          const signerAuthId = response.data.signerId !== null ? response.data.signerId.id : null;
          if (document.documentId == response.data.id) {
            document.hasAttachments = response.data.hasAttachments;
            document.checkAttachments = response.data.hasAttachments,
              document.hasPendingSignerForm = response.data.hasPendingSignerForm;
            document.hasSignerForm = response.data.hasSignerForm;
            document.checkSignerForm = response.data.hasSignerForm;
            document.auth_id = signerAuthId;
          }
        });

        localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));


      } catch (error) {
        this.loading = false;
        if (error.response.status == 403) {
          this.error = true;
          this.errorMsg = error.response.data.description;
        }
      }
    }
  },


  created() {
    this.token = this.$route.query.token || null;
    this.authCitizenId = this.$route.query.auth || null;
    this.authId = this.$route.query.authId || null;
    if (this.token) {
      localStorage.removeItem("documentCheckList");
      localStorage.removeItem("auth_user_token");
      localStorage.setItem("file_name", null);
      localStorage.setItem("transaction_id", null);
      localStorage.setItem("access_token", "");
      localStorage.setItem("auth_id", "");
      localStorage.setItem("request_id", "");
      localStorage.setItem("type", "");
      localStorage.setItem("formAnswers", "");
      localStorage.setItem("reviewed_list", "");
      localStorage.setItem("pdfjs.history", "");

      localStorage.setItem("auth_user_token", this.token);
      this.loadDocumentList();
    }
  },
}
</script>

<style lang="scss" scoped>
.div-documents {
  p {
    margin-bottom: 0px;
  }

  .document-item {
    margin-left: 15px;
  }

  .v-list {
    padding: 0;
  }

  .v-list-item {
    background-color: #fff;
  }
}

.v-input--checkbox {
  margin-top: 0px !important;
}

.bullet-list .v-list-item::before {
  content: '•';
  /* Unicode character for a bullet */
  color: black;
  /* Bullet color */
  display: inline-block;
  width: 1em;
  /* Space before bullet */
  margin-left: -1em;
  /* Pull bullet outside the content */
}
